import RoleGuard from "@/components/common/RoleGuard/RoleGuard.vue";

export default [
  {
    path: "",
    component: RoleGuard,
    props: {
      allowedRoles: ["ADMINISTRATOR"],
      redirectPath: "/",
    },
    children: [
      {
        path: "/Setup/groups",
        name: "GroupConfiguration",
        component: () => import("../pages/group/GroupConfiguration.vue"),
      },
      {
        path: "/Setup/groups/create",
        name: "GroupCreate",
        component: () => import("../pages/group/GroupCreate.vue"),
      },
      {
        path: "/Setup/groups/:id/modify",
        name: "GroupModify",
        component: () => import("../pages/group/GroupCreate.vue"),
      },
    ],
  },
];
