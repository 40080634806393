import httpClient from "../httpClient";

export default {
  getProfileWizardStep(projectId, profileId) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/project/${projectId}/profile/${profileId}`)
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  openProfileWizard(projectId, profileId) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/project/${projectId}/profile/${profileId}`)
        .then((res) => {
          const param = {
            ...res.data.result,
            wizard_property: "1",
          };
          httpClient
            .put(`/v1/project/${projectId}/profile/${profileId}`, param)
            .then((res) => {
              resolve(res.data.result);
            })
            .catch((err) => {
              reject(err.response);
            });
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  closeProfileWizard(projectId, profileId) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/project/${projectId}/profile/${profileId}`)
        .then((res) => {
          const param = {
            ...res.data.result,
            wizard_property: "",
          };
          httpClient
            .put(`/v1/project/${projectId}/profile/${profileId}`, param)
            .then((res) => {
              resolve(res.data.result);
            })
            .catch((err) => {
              reject(err.response);
            });
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  setProfileWizardStep(projectId, profileId, step) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/project/${projectId}/profile/${profileId}`)
        .then((res) => {
          const param = {
            ...res.data.result,
            wizard_property: String(step),
          };
          httpClient
            .put(`/v1/project/${projectId}/profile/${profileId}`, param)
            .then((res) => {
              resolve(res.data.result);
            })
            .catch((err) => {
              reject(err.response);
            });
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
};
