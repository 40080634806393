import ProjectGuard from "@/components/common/ProjectGuard/ProjectGuard";

export default [
  {
    path: "projects",
    component: ProjectGuard,
    children: [
      {
        name: "IngressDetail",
        path: ":projectId/profiles/:profileId/ingresses/:ingressId",
        component: () => import("../pages/ingress/IngressDetail.vue"),
        meta: {
          type: "detail",
        },
      },
      {
        name: "IngressEditPage",
        path: "/:projectId/profiles/:profileId/ingresses/:ingressId/edit",
        component: () => import("../pages/ingress/IngressEditPage.vue"),
      },
    ],
  },
];
