import ProjectAuthProvider from "@/components/common/ProjectAuthProvider/ProjectAuthProvider";
import ProfileGuard from "@/components/common/ProfileGuard/ProfileGuard";

export default [
  /** 프로젝트 관리 리스트 */
  {
    path: "/Setup/projects",
    name: "ProjectConfiguration",
    component: () => import("../pages/project/ProjectConfiguration.vue"),
  },
  /** 프로젝트 관리 생성 */
  {
    path: "/Setup/projects/create",
    name: "ProjectCreate",
    component: () => import("../pages/project/ProjectCreate.vue"),
  },
  /** 프로젝트 관리 상세 */
  {
    path: "/Setup",
    component: ProjectAuthProvider,
    children: [
      {
        path: "",
        component: ProfileGuard,
        children: [
          {
            path: "projects/:projectId",
            name: "ProjectDetail",
            component: () => import("../pages/project/ProjectNewDetail.vue"),
          },
        ],
      },
    ],
  },
];
