import { deepCopy } from "@/service/topologyHelper";

export default {
  install(Vue) {
    Vue.prototype.$helper = {
      deepCopy,
    };
  },
};

if (window) {
  /** 기존 사용하던 `require`의 폴리필 */
  window.require = (path) => {
    const replacePath = alias
      .filter(([reg]) => reg.test(path))
      .reduce((acc, [reg, replace]) => acc.replace(reg, replace), path);

    return replacePath;
  };
}

const alias = [
  [/^@\//, new URL("../../src", import.meta.url).href + "/"],
  [
    /^~\//,
    new URL("../../src/pages/main/modernframework", import.meta.url).href + "/",
  ],
];
