import ProjectAuthProvider from "@/components/common/ProjectAuthProvider/ProjectAuthProvider";
import ProfileGuard from "@/components/common/ProfileGuard/ProfileGuard";

export default [
  {
    path: "/projects",
    component: ProjectAuthProvider,
    children: [
      {
        path: "",
        component: ProfileGuard,
        children: [
          /** ===== 프로파일 ===== */
          {
            path: ":id",
            name: "ProfileList",
            component: () => import("../pages/profile/ProfileList.vue"),
          },
          {
            path: ":id/profiles/create",
            name: "ProfileCreate",
            component: () => import("../pages/profile/ProfileCreate.vue"),
          },
          {
            path: ":id/profiles/:profileId",
            name: "ProfileDetail",
            component: () => import("../pages/profile/ProfileDetailPage.vue"),
          },
          {
            path: ":id/profiles/:profileId/modify",
            name: "ProfileModify",
            component: () => import("../pages/profile/ProfileModify.vue"),
          },
          /** 프로파일 복제 */
          {
            path: ":projectId/profiles/:profileId/copy",
            name: "ProfileCopy",
            component: () => import("../pages/profile/ProfileCopy.vue"),
          },
        ],
      },
    ],
  },
];
