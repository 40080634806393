// @ts-check
import httpClient from "../httpClient";
import { request } from "../request";

/**
 * @typedef {object} Cluster
 * @property {number} id
 * @property {string} name
 * @property {string} domain
 * @property {string} access_token
 * @property {string} endpoint
 * @property {string} k8sagent
 * @property {string} kubernetes_version
 * @property {string} platform_type
 * @property {string} role
 * @property {string} created_at
 */

/**
 * @typedef ClusterAddParams
 * @type {Pick<Cluster, 'name'|'endpoint'|'k8sagent'|'access_token'>}
 */

/**
 * @typedef ClusterEditParams
 * @type {ClusterAddParams & {id:number}}
 */

/**
 * 클러스터 목록
 */
export const getClusterList = () =>
  request({
    method: "get",
    uri: "/v1/cluster",
  });

/**
 * 클러스터 단일 정보
 */
export const getCluster = (id) =>
  request({
    method: "get",
    uri: `/v1/cluster/${id}`,
  });

/**
 * 클러스터 생성
 * @param {ClusterAddParams} params
 */
export const addCluster = (params) =>
  request({
    method: "post",
    uri: "/v1/cluster",
    data: params,
  });

/**
 * 클러스터 수정
 * @param {ClusterEditParams} params0
 */
export const editCluster = ({ id, ...params }) =>
  request({
    method: "put",
    uri: `/v1/cluster/${id}`,
    data: params,
  });

/**
 * 클러스터 삭제
 * @param {number} clusterId
 */
export const removeCluster = (clusterId) =>
  request({
    method: "delete",
    uri: `/v1/cluster/${clusterId}`,
  });

export default {
  //코어 백엔드 영역
  getClusterList(clusterIds) {
    let url = `/v1/cluster`;
    if (clusterIds != null) {
      url = url + `?clusterIds=` + clusterIds;
    }
    return new Promise((resolve, reject) => {
      httpClient
        .get(url)
        .then((res) => {
          if (res && res.data && res.data.success === true) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  getCluster(id) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/cluster/${id}`)
        .then((res) => {
          if (res && res.data && res.data.success === true) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  getClusterAddonServiceList(clusterId) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/cluster/${clusterId}/addonservice`)
        .then((res) => {
          if (res && res.data && res.data.success === true) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  getClusterAddonServiceListByProjectId(clusterId, projectId, type) {
    return new Promise((resolve, reject) => {
      let url = `/v1/cluster/${clusterId}/addonservice?projectId=${projectId}`;
      if (type) {
        url += `&type=${type}`;
      }

      httpClient
        .get(url)
        .then((res) => {
          if (res && res.data && res.data.success === true) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  listArgoClusters() {
    return new Promise((resolve, reject) => {
      let url = `/v1/cluster/argocd`;

      httpClient
        .get(url)
        .then((res) => {
          if (res && res.data && res.data.success === true) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
};
